import {
  Text,
  VStack,
  Box,
  Link,
  Container,
  Grid,
  GridItem,
  Center,
} from '@chakra-ui/react';
import Image from 'next/image';

const Footer = () => {
  return (
    <Box as='footer' bg='#666' color='#EEE'>
      <Container maxW='1120px'>
        <Grid
          my={12}
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            xl: 'repeat(3, 1fr)',
          }}>
          <GridItem mb={{ base: 10, xl: 0 }}>
            <Text fontSize='lg' fontWeight='bold' pb={3}>
              USPACE 台北總部
            </Text>
            <VStack spacing={2} align='flex-start'>
              <Text color='#EEE' fontSize='sm'>
                service@uspace.city
              </Text>
              <Text color='#EEE' fontSize='sm'>
                台灣台北市中山區民生東路三段3號1樓
              </Text>
              <Text color='#EEE' fontSize='sm'>
                悠勢科技股份有限公司 統編(52492792)
              </Text>
            </VStack>
          </GridItem>
          <GridItem mb={{ base: 10, xl: 0 }}>
            <Text fontSize='lg' fontWeight='bold' pb={3}>
              USPACE 台中分部
            </Text>
            <VStack spacing={2} align='flex-start'>
              {/* <Text color='#EEE' fontSize='sm'>
                (04) 2452-5632
              </Text> */}
              <Text color='#EEE' fontSize='sm'>
                台灣台中市西屯區至善路121巷17號
              </Text>
            </VStack>
          </GridItem>
          <GridItem mb={{ base: 10, xl: 0 }}>
            <Text fontSize='lg' fontWeight='bold' pb={4}>
              相關連結
            </Text>
            <VStack spacing={2} align='flex-start'>
              <Link
                color='#EEE'
                href='https://drive.google.com/drive/folders/1htJQoy_UxZalQAVDjSnlC9o9S9kzYCS5?usp=sharing'
                variant='expansion'
                isExternal>
                Press Kit (品牌相關)
              </Link>
              <Link
                color='#EEE'
                href='https://www.facebook.com/UspaceTech/'
                variant='expansion'
                isExternal>
                Facebook
              </Link>
              <Link
                color='#EEE'
                href='https://www.instagram.com/uspace.tw/'
                variant='expansion'
                isExternal>
                Instagram
              </Link>
            </VStack>
          </GridItem>
        </Grid>
        <Center py={5}>
          <Text fontSize='sm' color='#eee'>
            {new Date().getFullYear()} USPACE .,co. All rights reserved.
          </Text>
        </Center>
      </Container>
    </Box>
  );
};

export default Footer;
