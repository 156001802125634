import Head from 'next/head';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useAtomValue } from 'jotai';
import { userAtom } from '@/atoms/user';

import Layout from '@/components/shared/Layout/Layout';
import { Navbar } from '@/components/shared/Navbar';
import Footer from '@/components/shared/Footer';
import { RecaptchaProvider } from '@/hooks/useRecaptcha';
import LoginForm from '@/components/pages/login/LoginForm';

export default function Login() {
  const router = useRouter();
  const user = useAtomValue(userAtom);
  if ((user.phone || user.email) && !router.query.callbackUrl) {
    router.push('/');
  }

  return (
    <Layout>
      <Head>
        <title key='title'>USPACE 月租停車專區｜登入</title>
      </Head>

      <Flex direction='column' minH='100vh'>
        <Navbar />

        <Flex direction='column' align='center' py='32px'>
          <Flex
            direction='column'
            align='center'
            px='20px'
            w={{ base: '100%', md: '75%' }}>
            <Box py={12}>
              <Text fontSize='20px' textAlign='center' mb={4}>
                登入
              </Text>
              <Text fontSize='12px' textAlign='center' mb={4} color='gray.500'>
                為確保您是本人，請先驗證您的手機號碼
              </Text>
              <RecaptchaProvider>
                <LoginForm />
              </RecaptchaProvider>
            </Box>
          </Flex>
        </Flex>

        {/* <Footer /> */}
      </Flex>
    </Layout>
  );
}

export async function getServerSideProps({ locale }) {
  return {
    props: {},
  };
}
