import React, {
  useEffect,
  useState,
  useCallback,
  createContext,
  useContext,
} from 'react';

const RecaptchaContext = createContext({
  token: null,
  setReCaptchaToken: () => {},
});

export const RecaptchaProvider = ({ children }) => {
  const [reCaptchaToken, setReCaptchaToken] = useState(null);

  const handleRecaptchaCallback = useCallback((token) => {
    setReCaptchaToken(token);
  }, []);

  useEffect(() => {
    window.handleRecaptchaCallback = handleRecaptchaCallback;
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RecaptchaContext.Provider
        value={{ token: reCaptchaToken, setReCaptchaToken: setReCaptchaToken }}>
        {children}
      </RecaptchaContext.Provider>
      <div
        className='g-recaptcha'
        data-sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY_INVISIBLE}
        data-callback='handleRecaptchaCallback'
        data-size='invisible'
        style={{ display: 'none' }}
      />
    </>
  );
};

export const useRecaptcha = () => {
  const token = useContext(RecaptchaContext).token;
  const [grecaptcha, setGrecaptcha] = useState(null);
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        window.grecaptcha &&
        typeof window.grecaptcha?.execute === 'function'
      ) {
        clearInterval(interval);
        setGrecaptcha(window.grecaptcha);
        window.grecaptcha.execute();
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const reset = () => {
    console.log('Google reCaptcha reset.');
    grecaptcha?.reset();
    setTimeout(() => {
      grecaptcha?.execute();
    }, 200);
  };

  const execute = () => {
    console.log('Google reCaptcha execute.');
    grecaptcha?.execute();
  };

  return { token, reset, execute };
};
