import { useState, useEffect } from 'react';
import {
  FormControl,
  Flex,
  Button,
  HStack,
  Input,
  useToast,
  Link,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useSearchParams } from 'next/navigation';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { useSession } from '@/hooks/useSession';
import { useRecaptcha } from '@/hooks/useRecaptcha';
import api from '@/services/apis';

const LoginForm = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const registrationSource = searchParams.get('registration_source') ?? 'upass';
  const { locale } = router;
  const { token: reCaptchaToken, reset: resetRecaptchaToken } = useRecaptcha();
  const session = useSession();
  const toast = useToast();
  const [passCodeCoolDown, setPassCodeCoolDown] = useState(0);

  const callbackUrl = router.query?.callbackUrl ?? '/';

  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('886');
  const [passCode, setPassCode] = useState('');
  const [errorOfPassCode, setErrorOfPassCode] = useState('');
  const [isPassCodeSent, setIsPassCodeSent] = useState(false);

  if (locale === 'jp' && countryCode !== '81') {
    setCountryCode('81');
  }

  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!reCaptchaToken) {
      resetRecaptchaToken();

      return;
    }

    let formatedPhoneNumber = phone.replace(countryCode, '');
    if (/^0[\d]+/.test(formatedPhoneNumber)) {
      formatedPhoneNumber = formatedPhoneNumber.replace(/^0/, '');
    }
    // 如果是台灣要補 0 否則訂單查不到
    if (countryCode === '886') {
      formatedPhoneNumber = `0${formatedPhoneNumber}`;
    }

    const params = {
      reCaptchaToken,
      countryCode,
      phoneNumber: formatedPhoneNumber,
    };

    const response = await api.fetchPassCode(params);

    if (response?.error) {
      console.log('Input:', params, 'Error:', response.error);
      toast({
        title: `簡訊發送失敗，若你曾刪除帳戶需要重啟請洽客服，或者稍後再試一次`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
      resetRecaptchaToken();
      return;
    }
    toast({
      title: `簡訊已發送`,
      position: 'top-right',
      isClosable: true,
    });
    setPassCodeCoolDown(response.cooldown);
    setIsPassCodeSent(true);
    setErrorOfPassCode('');
    resetRecaptchaToken();
  };
  const handleVerifyPassCode = async (e) => {
    setPassCode(e.target.value);
    if (e.target?.value.length === 4 && phone.length > 0) {
      const passCode = e.target.value;

      let formatedPhoneNumber = phone.replace(countryCode, '');
      if (/^0[\d]+/.test(formatedPhoneNumber)) {
        formatedPhoneNumber = formatedPhoneNumber.replace(/^0/, '');
      }
      // 如果是台灣要補 0 否則訂單查不到
      if (countryCode === '886') {
        formatedPhoneNumber = `0${formatedPhoneNumber}`;
      }

      const params = {
        countryCode,
        phoneNumber: formatedPhoneNumber,
        passCode,
        registrationSource,
      };
      const response = await api.fetchAuthToken(params);

      if (response?.error) {
        session.signOut();
        setPassCode('');
        setErrorOfPassCode('驗證碼錯誤');
        console.log('Input:', params, 'Error:', response.error);
        return;
      }
      session.signIn({
        accessToken: response.token,
      });
      setIsPassCodeSent(false);
    }
  };

  useEffect(() => {
    if (router.isReady && router.query?.phone) {
      // 情境：簡訊連結直接帶入電話增加使用者體驗。
      // 格式為不含國碼
      setPhone(router.query.phone);
    }
  }, [router.isReady, router.query]);

  useEffect(() => {
    const seconds = passCodeCoolDown;
    const timer = setInterval(() => {
      if (seconds > 0) {
        setPassCodeCoolDown((prev) => prev - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [passCodeCoolDown]);

  useEffect(() => {
    if (session.accessToken) {
      router.push(callbackUrl ?? '/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.accessToken]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        {!isPassCodeSent ? (
          <>
            <FormControl isRequired mb={4}>
              <Flex minH={10}>
                <PhoneInput
                  inputStyle={{ height: '100%', width: '100%' }}
                  enableSearch={true}
                  countryCodeEditable={false}
                  preferredCountries={['tw', 'us', 'jp']}
                  country={locale === 'jp' ? 'jp' : 'tw'}
                  value={countryCode}
                  masks={{
                    tw: '....-...-...',
                    jp: '...-....-....',
                  }}
                  onChange={(phone, { dialCode }) => {
                    setPhone(phone);
                    setCountryCode(dialCode);
                  }}
                />
              </Flex>
            </FormControl>
            <Flex>
              <Button
                isDisabled={
                  phone.replace(countryCode, '').length === 0 ||
                  passCodeCoolDown > 0
                }
                colorScheme='red'
                type='submit'
                w='100%'
                fontSize={12}>
                {passCodeCoolDown > 0
                  ? `${passCodeCoolDown}`
                  : '同意條款並發送驗證碼'}
              </Button>
            </Flex>
            <Flex justifyContent='center' my={4}>
              <Link href='https://uspace.city/app/usage/'>
                <Text fontSize='12px' color='blue.400'>
                  使用規約
                </Text>
              </Link>
              <Text fontSize='12px' px={2}>
                和
              </Text>
              <Link href='https://uspace.city/app/privacy_policy/'>
                <Text fontSize='12px' color='blue.400'>
                  隱私權條款
                </Text>
              </Link>
            </Flex>
          </>
        ) : null}

        {isPassCodeSent ? (
          <FormControl id='phone_number'>
            <HStack>
              <Input
                placeholder='請輸入 4 碼驗證碼'
                type='number'
                pattern='[0-9]*'
                inputMode='numeric'
                maxLength={4}
                value={passCode}
                onChange={handleVerifyPassCode}
              />
            </HStack>
            {errorOfPassCode ? (
              <Flex justifyContent='center' my={4}>
                <Text fontSize='12px' color='red.500'>
                  {errorOfPassCode}
                </Text>
              </Flex>
            ) : null}
            <Flex my={4}>
              <Button
                isDisabled={
                  phone.replace(countryCode, '').length === 0 ||
                  passCodeCoolDown > 0
                }
                variant='link'
                type='submit'
                w='100%'
                fontSize={12}>
                {passCodeCoolDown > 0 ? (
                  `${passCodeCoolDown}`
                ) : (
                  <Text color='blue.500'>重送驗證碼</Text>
                )}
              </Button>
            </Flex>
            <Flex my={4}>
              <Button
                variant='link'
                w='100%'
                fontSize={12}
                onClick={() => {
                  setIsPassCodeSent(false);
                  setPassCode('');
                }}>
                <Text color='gray.400'>變更電話號碼</Text>
              </Button>
            </Flex>
          </FormControl>
        ) : null}
      </form>
    </>
  );
};

export default LoginForm;
